<template>
  <div class="card p-2">
    <div class="col-12 col-lg-12 col-xxl-12">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-5">
          <div class="mb-1 row">
            <label for="colFormLabel" class="col-3 col-sm-3 col-lg-12 col-xl-3 col-form-label">
              Module
            </label>
            <div class="col-9 col-sm-9 col-lg-12 col-xl-9">
              <v-select
                  placeholder="Select Module"
                  v-model="moduleId"
                  :options="modules"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <button
              style="min-width: 64px;"
              @click="getMenu"
              class="btn btn-primary btn-block waves-effect waves-float waves-light mb-1"
          >Go
          </button>
        </div>

        <div class="col-md-5">
          <button
              @click="updateMenuSchema"
              class="btn btn-success ms-2 btn-block waves-effect waves-float waves-light mb-1"
          >Update
          </button>
          <router-link
              class="btn btn-secondary float-lg-end float-end mb-1 ms-2"
              :to="{name: 'MenuDesign', params:{moduleId: moduleId, companyId:companyId} }">
            Menu Design
          </router-link>
        </div>
      </div>
    </div>


    <div id="menu-sorting" class="row">
      <div class="col-md-6">
        <MenuSortingList
            :tasks="menuModified"
        />
      </div>
    </div>
    <Loader v-if="loading"/>
  </div>
</template>

<script>
import MenuSortingList from '@/components/molecule/company/company-setting/edit/MenuSortingList.vue'
import Loader from '@/components/atom/LoaderComponent'
import {mapGetters, useStore} from 'vuex'
import handleCompany from '@/services/modules/company'
import {computed, inject} from 'vue'

export default {
  name: 'MenuSorting',

  components: {
    MenuSortingList,
    Loader
  },

  data: () => ({
    menuOriginal: {},
    menuModified: [],
    companies: [],
    moduleId: null,
  }),

  computed: {
    ...mapGetters({
      modules: 'company/modules'
    }),

    companyId() {
      return this.$route.params.companyId
    }
  },

  methods: {
    async getMenu() {
      let query = `?company_id=${this.companyId}&module_id=${this.moduleId}`
      try {
        this.loading = true
        let res = await this.fetchMenu(query)
        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          let array = res.data.company_menu;
          this.menuModified = array.map(({submenus: pages, ...rest}) => ({pages, ...rest}));
        }
      } catch (err) {
        if (!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    },

    async updateMenuSchema() {
      let data = {
        company_id: this.companyId,
        module_id: this.moduleId,
        menu_schema: JSON.stringify(this.menuModified.map(({pages: submenus, ...rest}) => ({submenus, ...rest})))
      }
      try {
        this.loading = true
        let res = await this.updateMenu(data)
        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.showSuccess(res.message)
          this.getMenu()
        }
      } catch (err) {
        if (!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    },
  },
  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const store = useStore()

    const {
      fetchMenu,
      updateMenu,
      loading
    } = handleCompany()

    return {
      modules: computed(() => store.state.company.modules),
      fetchMenu,
      updateMenu,
      loading,
      showError,
      showSuccess
    }
  },

  created() {
    this.moduleId = parseInt(this.$route.params.moduleId)
    this.getMenu()
  }
}
</script>
