<template>
  <draggable
      class="dragArea"
      tag="ul"
      :list="tasks"
      :group="{ name: 'g1' }"
      item-key="name"
  >
    <template #item="{ element }">
      <li>
        <p>{{ element.name }}</p>
        <nested-draggable :tasks="element?.pages"/>
      </li>
    </template>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";

export default {
  props: {
    tasks: {
      default: [],
      type: Array
    }
  },
  components: {
    draggable
  },
  name: "nested-draggable"
};
</script>
<style scoped>
#menu-sorting ul, #menu-sorting li {
  list-style: none;
}

.dragArea p {
  border: 1px solid #ddd;
  position: relative;
  padding: 5px 15px;
  height: auto;
  min-height: 20px;
  width: 382px;
  line-height: 30px;
  overflow: hidden;
  word-wrap: break-word;
}
</style>
